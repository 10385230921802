import React from 'react'

// Hooks
import { useParams } from '../../hooks'

// Components
import TeckCda from '../../components/Teck/Cda'
// import TeckDemo from '../../components/Teck/Demo'
// import TeckLaboratory from '../../components/Teck/Laboratory'
// import TeckPelambres from '../../components/Teck/Pelambres'
// import PageNotFound from '../NotFound'

const PageTecks = ({ auth }) => {
  const { name } = useParams()

  switch (name) {
    case 'cda':
      return <TeckCda />
    // case 'demo':
    //   return <TeckDemo />
    // case 'laboratory':
    //   return <TeckLaboratory />
    // case 'pelambres':
    //   return <TeckPelambres />
    default:
      return <PageNotFound />
  }
}

export default PageTecks
